import React, { useRef, useEffect } from 'react';

const FullTranscript = ({ transcript, currentEntry }) => {
  const currentEntryRef = useRef(null);

  useEffect(() => {
    if (currentEntryRef.current) {
      currentEntryRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [currentEntry]);

  return (
    <div style={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid #ddd', borderRadius: '4px', padding: '10px' }}>
      {transcript.map((entry) => (
	      <>
        <span
          key={entry.id}
          ref={entry.id === currentEntry?.id ? currentEntryRef : null}
          style={{
            fontWeight: entry.id === currentEntry?.id ? 'bold' : 'normal',
            color: entry.id === currentEntry?.id ? '#007bff' : 'inherit',
          }}
        >
          {entry.text}
        </span>
	      <span> </span></>
      ))}
    </div>
  );
};

export default FullTranscript;
