import React from 'react';
import { ListGroup } from 'react-bootstrap';

const AudioSelection = ({ audioList, onSelect }) => {
  return (
    <ListGroup>
      {audioList.map((item, index) => (
        <ListGroup.Item 
          key={index} 
          action 
          onClick={() => onSelect(item)}
        >
          {item.title}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default AudioSelection;
