import { timeToSeconds } from './timeUtils';

export function parseSRT(srtContent) {
  if (typeof srtContent !== 'string' || srtContent.trim() === '') {
    console.error('Invalid SRT content:', srtContent);
    return [];
  }

  const entries = srtContent.trim().split('\n\n');
  return entries.map(entry => {
    const lines = entry.split('\n');
    if (lines.length < 3) {
      console.error('Invalid SRT entry:', entry);
      return null;
    }
    const [id, time, ...textLines] = lines;
    const [start, end] = time.split(' --> ');
    return {
      id: parseInt(id),
      start: timeToSeconds(start),
      end: timeToSeconds(end),
      text: textLines.join(' ')
    };
  }).filter(entry => entry !== null);
}
