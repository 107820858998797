import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStop } from '@fortawesome/free-solid-svg-icons';
import { Howl } from 'howler';
import { formatTime } from '../utils/timeUtils';
import { parseSRT } from '../utils/srtParser';
import TranscriptView from './TranscriptView';

const AudioPlayer = ({ title, audioSrc, subtitlesSrc }) => {
  const [transcript, setTranscript] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const playerRef = useRef(null);

  useEffect(() => {
    fetch(subtitlesSrc)
      .then(response => response.text())
      .then(data => {
        const parsedTranscript = parseSRT(data);
        setTranscript(parsedTranscript);
      })
      .catch(error => {
        console.error('Error fetching transcript:', error);
        setError('Nie udało się załadować transkrypcji.');
      });

    playerRef.current = new Howl({
      src: [audioSrc],
      html5: true,
      onload: () => setDuration(playerRef.current.duration()),
      onplay: () => setIsPlaying(true),
      onpause: () => setIsPlaying(false),
      onstop: () => {
        setIsPlaying(false);
        setCurrentTime(0);
      },
      onseek: () => setCurrentTime(playerRef.current.seek()),
    });

    return () => {
      if (playerRef.current) {
        playerRef.current.unload();
      }
    };
  }, [audioSrc, subtitlesSrc]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isPlaying && playerRef.current) {
        setCurrentTime(playerRef.current.seek());
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [isPlaying]);

  const handlePlayPause = () => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
  };

  const handleStop = () => {
    playerRef.current.stop();
  };

  const handleSeek = (time) => {
    playerRef.current.seek(time);
    setCurrentTime(time);
  };

  const getCurrentEntry = () => {
    return transcript.find(entry => currentTime >= entry.start && currentTime <= entry.end);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Button variant="outline-primary" onClick={handlePlayPause} className="mr-2">
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
              </Button>
              <Button variant="outline-secondary" onClick={handleStop}>
                <FontAwesomeIcon icon={faStop} />
              </Button>
            </div>
            <Form.Group>
              <Form.Control
                type="range"
                min={0}
                max={duration}
                value={currentTime}
                onChange={(e) => handleSeek(parseFloat(e.target.value))}
              />
            </Form.Group>
            <div className="mb-3">
              Czas: {formatTime(currentTime)} / {formatTime(duration)}
            </div>
          </Col>
          <Col md={6}>
            <TranscriptView
              currentEntry={getCurrentEntry()}
              transcript={transcript}
              onSeek={handleSeek}
              currentTime={currentTime}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AudioPlayer;
