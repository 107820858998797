import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import CurrentTranscript from './CurrentTranscript';
import FullTranscript from './FullTranscript';

const TranscriptView = ({ currentEntry, transcript, onSeek, currentTime }) => {
  const [showFullTranscript, setShowFullTranscript] = useState(false);

  const currentIndex = transcript.findIndex(entry => entry.id === currentEntry?.id);
  const previousEntry = currentIndex > 0 ? transcript[currentIndex - 1] : null;
  const nextEntry = currentIndex < transcript.length - 1 ? transcript[currentIndex + 1] : null;

  return (
    <div>
      <Button
        variant="outline-info"
        onClick={() => setShowFullTranscript(!showFullTranscript)}
        className="mb-3"
      >
        <FontAwesomeIcon icon={showFullTranscript ? faAlignLeft : faList} className="mr-2" />
        {showFullTranscript ? 'Pokaż bieżący fragment' : 'Pokaż pełną transkrypcję'}
      </Button>

      {showFullTranscript ? (
        <FullTranscript transcript={transcript} currentEntry={currentEntry} />
      ) : (
        <CurrentTranscript
          currentEntry={currentEntry}
          previousEntry={previousEntry}
          nextEntry={nextEntry}
        />
      )}
    </div>
  );
};

export default TranscriptView;
