import React, { useState, useEffect } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from './components/AudioPlayer';
import AudioSelection from './components/AudioSelection';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [audioList, setAudioList] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('content.json')
      .then(response => response.json())
      .then(data => {
        if (!Array.isArray(data)) {
          throw new Error('Invalid data format in content.json');
        }
        setAudioList(data);
      })
      .catch(error => {
        console.error('Error fetching audio list:', error);
        setError('Nie udało się załadować listy audio. Proszę spróbować ponownie później.');
      });
  }, []);

  const handleAudioSelect = (audio) => {
    setSelectedAudio(audio);
  };

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container className="py-4">
      <h1 className="mb-4">Audio Player</h1>
      {!selectedAudio ? (
        <AudioSelection audioList={audioList} onSelect={handleAudioSelect} />
      ) : (
        <>
          <Button variant="outline-secondary" onClick={() => setSelectedAudio(null)} className="mb-3">
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Powrót do listy
          </Button>
          <AudioPlayer
            title={selectedAudio.title}
            audioSrc={selectedAudio.audio}
            subtitlesSrc={selectedAudio.subtitles}
          />
        </>
      )}
    </Container>
  );
}

export default App;
