import React from 'react';
import { Card } from 'react-bootstrap';
import { formatTime } from '../utils/timeUtils';

const CurrentTranscript = ({ currentEntry, previousEntry, nextEntry }) => {
  return (
    <div>
      {previousEntry && (
        <Card className="mb-2 text-muted">
          <Card.Body>
            <Card.Subtitle className="mb-2">
              {formatTime(previousEntry.start)} - {formatTime(previousEntry.end)}
            </Card.Subtitle>
            <Card.Text>{previousEntry.text}</Card.Text>
          </Card.Body>
        </Card>
      )}
      {currentEntry && (
        <Card className="mb-2 border-primary">
          <Card.Body>
            <Card.Subtitle className="mb-2 text-primary">
              {formatTime(currentEntry.start)} - {formatTime(currentEntry.end)}
            </Card.Subtitle>
            <Card.Text>{currentEntry.text}</Card.Text>
          </Card.Body>
        </Card>
      )}
      {nextEntry && (
        <Card className="mb-2 text-muted">
          <Card.Body>
            <Card.Subtitle className="mb-2">
              {formatTime(nextEntry.start)} - {formatTime(nextEntry.end)}
            </Card.Subtitle>
            <Card.Text>{nextEntry.text}</Card.Text>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default CurrentTranscript;
